// import { useContext } from "react";
// import { OverlayContext } from "old_components/Context/OverlayContext";
// import { useHistory } from "react-router-dom";

// Css
import "./KycNotification.scss";

// Framer motion
import { AnimatePresence, motion } from "framer-motion";

// Mui
import CloseIcon from "@mui/icons-material/Close";

const KycNotification = ({
  showNotification = false,
  NotificationTitle = "Your documents have been verified",
  NotificationContent = "You can proceed to complete your KYC",
  NotificationOnClick = false,
  NotificationButtonTitle = "Proceed",
  showButton,
  closeNotification,
}) => {
  // const { setNotification } = useContext(OverlayContext);

  return (
    <AnimatePresence>
      {showNotification && (
        <motion.div
          key="modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div
            className="d-flex justify-content-center"
            style={{ zIndex: 999 }}
          >
            <div className="notification__container">
              <div className="sidebar"></div>
              <div className="notification__box">
                <h3>{NotificationTitle}</h3>
                <h4>{NotificationContent}</h4>
              </div>
              <div className="notification__btn d-flex align-items-center">
                {showButton && (
                  <span onClick={NotificationOnClick}>
                    {NotificationButtonTitle}
                  </span>
                )}
                <CloseIcon
                  className="closeicons"
                  onClick={closeNotification}
                  role={"button"}
                />
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default KycNotification;
