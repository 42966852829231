import { sha256 } from 'js-sha256';
import fleekStorage from '@fleekhq/fleek-storage-js';

export const escapeRegExp = function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const replaceAll = function replaceAll(str, needle, replace) {
    if (str === '/static/sitemap') str = 'sitemap';
    return str.replace(new RegExp(escapeRegExp(needle), 'g'), replace);
};

export const createHash = (password) => {
    try {
        const hash = sha256.create();
        hash.update(password);
        return hash.hex();
    } catch (error) {
        throw error;
    }
};

export const compactAddress = (text) => {
    if (text) {
        var lastFour = text.substr(text.length - 5); // => "Tabs1"
        var firstFour = text.substr(0, 4); // => "1"

        return firstFour + '...' + lastFour;
    } else {
        return '';
    }
};

export const truncateText = (text, textLen) => {
    // textLen => length of the text till we have to truncate
    if (text) {
        if (text.length > textLen) {
            let truncateText = text.substr(0, textLen);
            return truncateText + '...';
        } else {
            return text;
        }
    } else {
        return '';
    }
};

export const ValidateEmail = (email) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
    }
    return false;
};

export const createImageURL = (url = null) => url && URL.createObjectURL(url);

export const removeEmpty = (obj) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
    );
};

export const openInNewTab = (url) => {
    window.open(url, '_blank').focus();
};

export const getSecondsBetweenDates = (date1, date2) => {
    var a = new Date(date1);

    var b = new Date(date2);
    var difference = (b - a) / 1000;

    //console.log("You waited: " + difference + " seconds");
    return difference;
};

export const checkValidDate = (d) => {
    if (Object.prototype.toString.call(d) === '[object Date]') {
        // it is a date
        if (isNaN(d)) {
            // d.getTime() or d.valueOf() will also work
            // date object is not valid
            return false;
        } else {
            // date object is valid
            return true;
        }
    } else {
        // not a date object
        return false;
    }
};

export const ISOtoDateTimeLocal = (date) => {
    const dateTimeISOString = date;
    const dateTime = new Date(dateTimeISOString);
    // const tzoffset = new Date().getTimezoneOffset() * 60000;
    const localISOTime = new Date(
        dateTime
        // - tzoffset
    )
        .toISOString()
        .slice(0, -1);
    return `${localISOTime}Z`;
};

export const isEmpty = (object) => {
    if (
        typeof object === 'object' &&
        !Array.isArray(object) &&
        object !== null
    ) {
        const empty = !Object.values(object).some(
            (x) => x !== null && x !== ''
        );
        return empty;
    } else {
        return true;
    }
};

export const compareMilestones = (a, b) => {
    if (a.milestone_no < b.milestone_no) {
        return -1;
    }
    if (a.milestone_no > b.milestone_no) {
        return 1;
    }
    return 0;
};

export const copyToClipboard = (text) => {
    window.prompt('Copy to clipboard: Ctrl+C/Cmd+C, Enter', text);
};

export const getAcronym = (str) => {
    var matches = str.match(/\b(\w)/g);
    var acronym = matches.join('');
    //console.log(acronym)

    return acronym;
};

export const getSecondsFromNumberOfDays = (days) => {
    var seconds = days * 24 * 60 * 60;
    return seconds;
};

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const uploadToFleekStorage = async (file) => {
    return new Promise(async (resolve, reject) => {
        try {
            const uploadedFile = await fleekStorage.upload({
                apiKey: process.env.REACT_APP_FLEEK_API_KEY,
                apiSecret: process.env.REACT_APP_FLEEK_API_SECRET,
                key: 'license_file',
                data: file,
                httpUploadProgressCallback: (event) => {
                    //console.log(Math.round(event.loaded / event.total * 100) + '% done');
                },
            });
            const license_file = uploadedFile.publicUrl;
            return resolve(license_file);
        } catch (error) {
            return reject(error);
        }
    });
};

export const imageWidthAndHeight = (provideFile) => {
    // take the given file (which should be an image) and return the width and height
    const imgDimensions = { width: null, height: null };

    return new Promise((resolve) => {
        const reader = new FileReader();

        reader.readAsDataURL(provideFile);
        reader.onload = function () {
            const img = new Image();
            img.src = reader.result;

            img.onload = function () {
                imgDimensions.width = img.width;
                imgDimensions.height = img.height;

                resolve(imgDimensions);
            };
        };
    });
};

function dateRangeOverlaps(a_start, a_end, b_start, b_end) {
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
    if (b_start < a_start && a_end < b_end) return true; // a in b
    return false;
}

export const multipleDateRangeOverlaps = (timeEntries) => {
    let i = 0,
        j = 0;
    let timeIntervals = timeEntries.filter(
        (entry) => entry.from != null && entry.to != null
    );
    if (timeIntervals != null && timeIntervals.length > 1)
        for (i = 0; i < timeIntervals.length - 1; i += 1) {
            for (j = i + 1; j < timeIntervals.length; j += 1) {
                if (
                    dateRangeOverlaps(
                        timeIntervals[i].from.getTime(),
                        timeIntervals[i].to.getTime(),
                        timeIntervals[j].from.getTime(),
                        timeIntervals[j].to.getTime()
                    )
                )
                    return true;
            }
        }
    return false;
};

export const kFormatter = (num) => {
    if (typeof parseInt(num) === 'NaN') return 0;
    return Math.abs(num) > 1000
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K'
        : Math.sign(num) * Math.abs(num);
};

export const copyText = (text) => {
    var input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
};

const compactNum = (text) => {
    if (text) {
        if (text.length > 8) {
            var last = text.substr(text.length - 1); // => "Tabs1"
            var first = text.substr(0, 5); // => "1"

            return first + '...' + last;
        } else {
            return text;
        }
    } else {
        return '';
    }
};
export const formattedNumber = (number) => {
    return compactNum(
        Intl.NumberFormat('en-US', {
            maximumFractionDigits: 1,
            notation: 'compact',
            compactDisplay: 'short',
        }).format(number || 0)
    );
};

export const errorParser = (error) => {
    if (error?.message?.includes('below min')) {
        return {
            message:
                'Minimum balance error: Add 0.5 Algos to your account and try again',
        };
    } else if (error?.message?.includes('pc=818')) {
        return {
            message:
                'An error occurred while setting up your investment. Please try again.',
            exceeded: true,
        };
    } else {
        return error;
    }
};

export const decimalFormatter = (number) => {
    return number.toFixed(2).replace(/[.,]00$/, '');
};

export const GetKycLocal = () => {
    return localStorage.getItem('kyc_progress');
};

export const setKycLocal = (item) => {
    return localStorage.setItem('kyc_progress', item);
};

export const availableFunds = (
    total,
    milestones,
    commission_fee_percentage = 5
) => {
    let used = 0;
    milestones?.map((milestone) => {
        used += parseFloat(milestone?.fundsRequired || 0);
    });

    return total - (total * commission_fee_percentage) / 100 - used;
};

export const inputDecimalLimit = (e) => {
    let t = e.target.value;
    return (t =
        t.indexOf('.') >= 0
            ? t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), 7)
            : e.target.value);
};
export const maxDecimalFormatter = (number) => {
    return number
        .toFixed(6)
        .replace(/^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[0-9]*?)0*$/, '$1$2$3');
};

export const commaFormatter = (value) => {
    let num = parseFloat(value);
    return num.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 6,
    });
};

export const inputMaxValue = (values) => {
    // This function works with react-number-formatter package only
    const { formattedValue, floatValue } = values;
    return formattedValue === '' || floatValue <= 990000000;
};

// multiples by 100 logic
export const handleMultipleOfHundred = (inputValue) => {
    if (parseInt(inputValue) % 100 !== 0) {
        return 'Please enter an amount in multiples of 100.';
    }

    return false;
};

export const handleDocCategoryPayload = (category) => {
    let lowerCat = category.toLowerCase();
    let res = '';

    for (let i = 0; i < lowerCat.length; i++) {
        if (lowerCat[i] === ' ') {
            res += '_';
        } else if (lowerCat[i] === '&') {
            res += 'and';
        } else {
            res += lowerCat[i];
        }
    }
    return res;
};
