export const footer_routes = [
    {
        path: '/about-us',
        name: 'About Us',
    },
    {
        path: '/contact-us',
        name: 'Contact Us',
    },
    {
        path: '/terms-and-conditions',
        name: 'Terms & Conditions',
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
    },
];

export const navbar_routes = [
    {
        path: '/',
        name: 'Home',
    },
    {
        path: '/blogs',
        name: 'Blogs',
    },
    {
        path: '/marketplace',
        name: 'Marketplace',
    },
    {
        path: '/favourites',
        name: 'Favourites',
    },
];

export const routes = {
    // marketplace: '/marketplace',
    // blogs: '/blogs',
    // favourites: '/favourites',

    // //login
    // login: '/login',

    //home
    home: '/',

    //404
    not_found: '/404',

    //profile
    // profile: '/profile',

    //artist page
    // artist: '/artist/:id',

    //project page
    project: '/project/:id',
    editProject: '/project/:id/edit',

    //create project
    create_project: '/project/new',

    //signup
    signup: '/signup',

    //faq
    faq: '/faq',

    //AboutUs
    aboutus: '/aboutus',

    //ContactUs
    contactUs: '/contact-us',

    //KYC
    kyc: '/kyc',

    //privacy policy
    privacy_policy: '/privacy-policy',

    privacy_policy_copy: '/privacy-policy-copy',

    information_disclosure: '/information-disclosure',

    website_disclaimer: '/website-disclaimer',

    xfinite_technologies: '/disclaimer-and-disclosure',

    risk_disclosure: '/risk-disclosure',

    //t&c
    terms_and_conditions: '/terms-and-conditions',

    //wallet connect
    wallet_connect: '/wallet-connect',

    //edit profile:
    edit_profile: '/profile/:type/:id/edit',

    //profile
    profile: '/profile/:type/:id',

    complaint_handling: '/complaint-handling',
    complaint_handling_form: '/complaint-handling/form',

    // test-route
    health_check: '/healthcheck',
};

export const private_routes = {
    profile: routes.profile,
    create_project: routes.create_project,
    editProject: routes.editProject,
};
