import api from './api';

let profilev2 = {};
profilev2.DNS = api.DNS.v2;

let endPoints = {
    getArtistProfile: '/user/findAllCreator/',
    getInvestorProfile: '/user/findAllInvestor/',
    updateUserProfile: '/user/updateUser',
};

const postApiMaker = (endPoint) => (data, cb) => {
    api.post(profilev2.DNS + endPoint, data, cb);
};

const putApiMaker = (endPoint) => (data, cb) => {
    api.put(profilev2.DNS + endPoint, data, cb);
};

const patchApiMaker = (endPoint) => (data, cb) => {
    api.patch(profilev2.DNS + endPoint, data, cb);
};

const getApiMaker = (endPoint) => (data, cb) => {
    api.get(profilev2.DNS + endPoint, data, cb);
};

profilev2.getArtistProfile = function (data, cb) {
    const { id } = data;
    const _url = endPoints.getArtistProfile + '?id=' + id;
    return api.get(profilev2.DNS + _url, {}, cb);
};

profilev2.getInvestorProfile = function (data, cb) {
    const { id } = data;
    const _url = endPoints.getInvestorProfile + '?id=' + id;
    return api.get(profilev2.DNS + _url, {}, cb);
};

profilev2.updateUserProfile = patchApiMaker(endPoints.updateUserProfile);

export default profilev2;
