//react
import { useState, useEffect } from 'react';

//react router
import { useHistory } from 'react-router-dom';

//my algo
import MyAlgoConnect from '@randlabs/myalgo-connect';
import algosdk from 'algosdk';

//helpers
import {
    isLoggedIn,
    logout,
    saveAuthToken,
    saveToken,
    getAccountData,
    saveSession,
    getSession,
    removeFromSession,
} from 'functions/helpers/auth';

//config
import { ALGORAND_QUERY_SERVER, ASSET } from 'config';

//apis
import userAuth from 'functions/apis/userAuth';
import userAuthv2 from 'functions/apis/userAuthv2';

//routes
import { routes, private_routes } from 'routes';

//components
// import Loader from 'components/Loader/Loader';

//material ui
// import { Snackbar, Alert } from '@mui/material'

const useUserState = ({ setSnackbar, setLoading }) => {
    const history = useHistory();

    const algodClient = new algosdk.Algodv2('', ALGORAND_QUERY_SERVER, '');
    const myAlgoConnect = new MyAlgoConnect();
    const [walletAddress, setWalletAddress] = useState(
        getAccountData('wallet_address')
    );
    const [userType, setUserType] = useState(getAccountData('type'));
    const [walletData, setWalletData] = useState(null);
    const [loadingBalance, setLoadingBalance] = useState(false);

    useEffect(() => {
        isLoggedIn() && checkBalance();
    }, [walletAddress]);

    const disconnectWallet = () => {
        logout(false);
        setWalletAddress(null);
        setUserType(null);
        setWalletData(null);
        if (
            Object.values(private_routes).indexOf(history?.location?.pathname) >
            -1
        )
            history.push('/');
    };

    const checkAddressExists = (address) => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            userAuthv2.checkAddressExists(
                {
                    address,
                },
                (err, res) => {
                    setLoading(false);
                    if (err) {
                        //console.log(`err`, err)
                        setSnackbar({
                            message: err?.message || 'Wallet connection failed',
                            type: 'error',
                            open: true,
                        });
                        return resolve(false);
                    }
                    if (res) {
                        //console.log(`res`, res)

                        if (res?.data?.user) {
                            const _token = res?.data?.token?.data;
                            saveAuthToken(_token);
                            return resolve({
                                registered: true,
                                user: res?.data?.user,
                                type: res?.data?.type,
                            });
                        } else {
                            return resolve({
                                registered: false,
                                user: null,
                                type: null,
                            });
                        }
                    }
                }
            );
        });
    };

    const connectWallet = async () => {
        try {
            const accountsSharedByUser = await myAlgoConnect.connect({
                shouldSelectOneAccount: true,
            });
            if (accountsSharedByUser[0]?.address) {
                let userResponse = await checkAddressExists(
                    accountsSharedByUser[0]?.address
                );
                if (userResponse) {
                    //console.log('userResponse', userResponse)
                    if (userResponse?.registered) {
                        saveToken({
                            ...userResponse?.user,
                            type: userResponse?.type,
                        });
                        setUserType(userResponse?.type);
                        setWalletAddress(accountsSharedByUser[0]?.address);
                        setSnackbar({
                            message: 'Signed in successfully',
                            type: 'success',
                            open: true,
                        });
                        const returnURL = getSession('returnURL');
                        if (returnURL) {
                            removeFromSession('returnURL');
                            history.push(returnURL);
                        } else {
                            history.push('/');
                        }
                    } else {
                        //save session storage
                        saveSession('unregistered_user', {
                            address: accountsSharedByUser[0]?.address,
                        });
                        history.push(routes.signup);
                    }
                }
            }
        } catch (error) {
            //console.log(`error`, error)
            setSnackbar({
                message: error?.message || 'Wallet connection failed',
                type: 'error',
                open: true,
            });
        }
    };

   

    const checkBalance = async () => {
        // return new Promise(async (resolve, reject) => {
        try {
            setLoadingBalance(true);
            let accountInfo;
            let response = await algodClient
                .accountInformation(walletAddress)
                .do();
            accountInfo = response?.account || response;
            accountInfo.app_asset = accountInfo?.assets?.find(
                (v) => v['asset-id'] === ASSET.id
            ) || { amount: 0 };
            accountInfo.optIn = accountInfo?.assets?.some(
                (v) => v['asset-id'] === ASSET.id
            );
            accountInfo.apps = accountInfo?.['apps-local-state']?.map(
                (v) => v['id']
            );
            // accountInfo.low_balance = accountInfo.optIn ? (accountInfo?.amount < ((MINIMUM_ALGO_BALANCE) * ONE_ALGO) ? true : false) : (accountInfo?.amount < ((MINIMUM_ALGO_BALANCE * 2) * ONE_ALGO) ? true : false)
            // //console.log(`accountInfo`, accountInfo)
            setWalletData(accountInfo);
            setLoadingBalance(false);
            // return resolve()
        } catch (error) {
            //console.log(`error`, error)
            setLoadingBalance(false);
            // setSnackbar({
            //     message: error?.message || 'Failed to fetch balance',
            //     type: 'error',
            //     open: true
            // });
            // return reject(error)
        }
        // })
    };

    return {
        connectWallet,
        walletAddress,
        setWalletAddress,
        userType,
        setUserType,
        loadingBalance,
        walletData,
        setWalletData,
        checkBalance,
        disconnectWallet,
    };
};

export default useUserState;
