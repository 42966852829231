import { getAccountData, getLocal } from 'functions/helpers/auth';
import React, { useContext, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { useHistory } from 'react-router-dom';
import { OverlayContext } from 'old_components/Context/OverlayContext';
import { UserContext } from 'old_components/Context/UserContext';
import { updateUserSlug } from 'functions/helpers/updateUserSlug';

const KycWebSocket = () => {
    const history = useHistory();

    const { setNotification, setDisableStep } = useContext(OverlayContext);
    const { socketKycStatus, setSocketKycStatus } = useContext(UserContext);

    const closeNotification = () => {
        setNotification(false);
    };
    const KYCredirect = () => {
        history.push('/kyc');
        setNotification(false);
    };
    const token = getLocal('Authorization');
    const res = useWebSocket(
        'wss://staging.mzaalo.com/launchpads',

        {
            queryParams: { Authorization: token },
        }
    );
    const { sendMessage, lastMessage, readyState, getWebSocket } = res;

    const handleDisconnect = () => {
        const webSocketInstance = getWebSocket();
        if (webSocketInstance) {
            console.log('socket stopped');
            webSocketInstance.close();
        }
    };

    useEffect(() => {
        if (readyState === 1) {
            // Connection is open, send a message or perform any other actions
            console.log('socket started');
        }
    }, [readyState]);

    useEffect(() => {
        if (lastMessage) {
            const messageData = JSON.parse(lastMessage.data);

            messageData.status && setSocketKycStatus(messageData.status);

            if (messageData.status) {
                if (messageData.status === 'verified') {
                    handleDisconnect();
                }
            }
            if (messageData.error) {
                handleDisconnect();
                //setErrorPage
                messageData.error && setSocketKycStatus(messageData);
            }
        }
    }, [lastMessage]);

    useEffect(() => {
        if (socketKycStatus) {
            if (socketKycStatus === 'hv_verified') {
                setNotification({
                    showNotification: true,
                    NotificationTitle: 'Your documents have been verified',
                    NotificationContent:
                        'You can proceed to complete your KYC now',
                    NotificationOnClick: KYCredirect,
                    NotificationButtonTitle: 'Proceed',
                    showButton: true,
                    closeNotification: closeNotification,
                });
            }
            if (socketKycStatus === 'nft_minted') {
                setNotification({
                    showNotification: true,
                    NotificationTitle: 'KYC NFT Minted',
                    NotificationContent: 'Your KYC NFT was Minted Successfully',
                    NotificationOnClick: KYCredirect,
                    showButton: false,
                    closeNotification: closeNotification,
                });

                setTimeout(() => {
                    setNotification(false);
                }, 5000);

                setDisableStep(false);
            }
            if (socketKycStatus === 'verified') {
                setNotification({
                    showNotification: true,
                    NotificationTitle: 'KYC completed successfully',
                    NotificationContent:
                        'Your KYC NFT was transferred to you successfully',
                    NotificationOnClick: closeNotification,
                    NotificationButtonTitle: 'OK',
                    showButton: true,
                    closeNotification: closeNotification,
                });
                updateUserSlug();

                setTimeout(() => {
                    setNotification(false);
                }, 5000);
                handleDisconnect();
            }
        }
    }, [socketKycStatus]);
    return <></>;
};

export default KycWebSocket;
