import axios from 'axios';
import { DNS as envSpecificDNS, API_AUTH_KEY } from 'config';

import { getLocal } from 'functions/helpers/auth';

// every gobal api related things goes in this file
// any module specific apis should go inside their on own specific files by extending this file
let api = {};

api.DNS = envSpecificDNS;

// api.endPoints = {};
// globals
// axios.defaults.baseURL = api.DNS;
// add token to every request

// console.log(API_AUTH_KEY)

axios.interceptors.request.use(
    function (config) {
        config.headers['Access-Control-Allow-Origin'] = '*';
        let Authorization = getLocal('Authorization');
        if (Authorization) config.headers['Authorization'] = Authorization;
        config.headers['Ocp-Apim-Subscription-Key'] = API_AUTH_KEY;
        config.headers['Ocp-Apim-Trace'] = true;
        return config;
    },
    function (error) {
        //console.log(`error in api.js`, error)
        return Promise.reject(error);
    }
);

// apply interceptor on response
axios.interceptors.response.use(
    (response) => response,
    (error) => api.mapError(error)
);

// utils functions
// debuging only
api.logger = (err, res) => {
    if (err) {
        var errMsg = err.response.data.message || 'something went wrong';
        console.error(errMsg);
    }
    if (res && res.data.success) {
        console.info(res.data);
    }
};

// take the err obj and inject message
api.mapError = (err) => {
    if (err.response) {
        err.message = err.response.data.message || 'something went wrong';
        const status = err.response.status;
        if (
            status === 403 &&
            err.response.data.message === 'Missing Access token.'
        ) {
            localStorage.clear(); // logout the user
            localStorage.setItem('DisclaimerState', false);
            return;
        }
    } else if (err && typeof err === 'object') {
        if (err?.message === 'Network Error') {
            err['message'] = 'Network Error';
        } else {
            err['message'] = 'Error';
        }
    }
    return Promise.reject(err);
};

const handleCatch = (err, cb) => {
    if (err.response) {
        const originalRequest = err.config;
        const status = err.response.status;
        originalRequest._retry = false;
        cb(err, false);
    } else if (err && typeof err === 'object') {
        if (err?.message === 'Network Error') {
            err.msg = 'Network Error';
            cb(err, false);
        } else {
            err['msg'] = 'Error';
            cb(err, false);
        }
    } else {
        cb(err, false);
    }
};
// helper functions

api.get = function (endpoint, data, cb, config) {
    axios
        .get(endpoint, {
            params: data,
            ...config,
        })
        .then(function (response) {
            cb(false, response);
        })
        .catch((error) => handleCatch(error, cb))
        .then(function () {
            // Always runs
        });
};

api.post = function (endpoint, data, cb, params) {
    cb = cb || api.logger;
    axios
        .post(endpoint, data, { params: params })
        .then(function (response) {
            cb(false, response);
        })
        .catch((error) => handleCatch(error, cb));
};

api.put = function (endpoint, data, cb) {
    return axios
        .put(endpoint, data)
        .then(function (res) {
            cb(false, res);
        })
        .catch((error) => handleCatch(error, cb));
};

api.patch = function (endpoint, data, cb) {
    return axios
        .patch(endpoint, data)
        .then(function (res) {
            cb(false, res);
        })
        .catch((error) => handleCatch(error, cb));
};

api.delete = function (endpoint, data, cb) {
    return axios
        .delete(endpoint, data)
        .then(function (res) {
            cb(false, res);
        })
        .catch((error) => handleCatch(error, cb));
};

export default api;
