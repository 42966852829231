import api from './api';

let userAuth = {};
userAuth.DNS = api.DNS.v1;

let endPoints = {
    getImageUploadToken: '/getsastoken',
};

const postApiMaker = (endPoint) => (data, cb) => {
    api.post(userAuth.DNS + endPoint, data, cb);
};

const putApiMaker = (endPoint) => (data, cb) => {
    api.put(userAuth.DNS + endPoint, data, cb);
};

const getApiMaker = (endPoint) => (data, cb) => {
    api.get(userAuth.DNS + endPoint, data, cb);
};

//dummy
// userAuth.checkAddressExists = (data, cb) => cb(null, {
//   data: {
//     // user: {
//     //   name: 'Karan'
//     // },
//     // token: 'abcdxyz'
//   }
// })

userAuth.checkAddressExists = getApiMaker(endPoints.checkAddressExists);
userAuth.checkUsernameExists = getApiMaker(endPoints.checkUsernameExists);
userAuth.getImageUploadToken = getApiMaker(endPoints.getImageUploadToken);

export default userAuth;
