const _env = process.env.REACT_APP_ENV;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_VERSION_NUM = process.env.REACT_APP_VERSION?.replaceAll(
    '.',
    ''
);

export const isGuestMode = true;

export const reCAPTCHA_SITE_KEY = '6LesA_obAAAAAIjU6YI_fz7Arief8bVLGAOEhdAa';

const DEV_ALG_QUERY_SERVER = 'https://testnet-idx.algonode.cloud';
const STAGING_ALG_QUERY_SERVER = 'https://testnet-idx.algonode.cloud';
const PROD_ALG_QUERY_SERVER = 'https://testnet-idx.algonode.cloud';

const DEV_ASSET = {
    name: 'USDC',
    id: 161307100,
};
const STAGING_ASSET = {
    name: 'USDC',
    id: 161307100,
};
const PROD_ASSET = {
    name: 'USDC',
    id: 161307100,
};

const DEV_ALGO_EXPLORER_URL = 'https://testnet.algoexplorer.io';
const STAGING_ALGO_EXPLORER_URL = 'https://testnet.algoexplorer.io';
// const PROD_ALGO_EXPLORER_URL = 'https://algoexplorer.io/';
const PROD_ALGO_EXPLORER_URL = 'https://testnet.algoexplorer.io';

const DEV_ALGOCLIENT_API = 'https://testnet-algorand.api.purestake.io/ps2';
const STAGING_ALGOCLIENT_API = 'https://testnet-algorand.api.purestake.io/ps2';
// const PROD_ALGOCLIENT_API = 'https://mainnet-algorand.api.purestake.io/ps2';
const PROD_ALGOCLIENT_API = 'https://testnet-algorand.api.purestake.io/ps2';

export const ALGOCLIENT_API_KEY = process.env.REACT_APP_ALGO_CLIENT_API_KEY;
export const API_AUTH_KEY = process.env.REACT_APP_API_AUTH_KEY;

export const ALGORAND_QUERY_SERVER = getAlgorandQueryServer();
export const ASSET = getAsset();
export const ALGO_EXPLORER_URL = getAgloExplorerURL();
export const ALGOCLIENT_API = getAlgoClientApi();

export const MINIMUM_ALGO_BALANCE = 0.001;

///////////////////////////////////// API //////////////////////////////////////
const DEV_DNS = {
    v1: 'https://developmentbackend.mzaalonation.com/v1',
    v2: 'https://developmentbackend.mzaalonation.com/v2',
};

const STAGING_DNS = {
    v1: 'https://developmentbackend.mzaalonation.com/v1',
    v2: 'https://developmentbackend.mzaalonation.com/v2',
};

const PROD_DNS = {
    v1: 'https://prodbackend.mzaalonation.com/v1',
    v2: 'https://prodbackend.mzaalonation.com/v2',
};

///////////////////////////////////// API END //////////////////////////////////////

const getDNS = function () {
    let _dns = DEV_DNS;
    switch (_env) {
        case 'dev':
            _dns = DEV_DNS;
            break;
        case 'production':
            _dns = PROD_DNS;
            break;
        case 'staging':
            _dns = STAGING_DNS;
            break;
        default:
            break;
    }
    return _dns;
};
export const DNS = getDNS();

function getAlgorandQueryServer() {
    let _server = DEV_ALG_QUERY_SERVER;
    switch (_env) {
        case 'dev':
            _server = DEV_ALG_QUERY_SERVER;
            break;
        case 'production':
            _server = PROD_ALG_QUERY_SERVER;
            break;
        case 'staging':
            _server = STAGING_ALG_QUERY_SERVER;
            break;
        default:
            break;
    }
    return _server;
}

function getAsset() {
    let _server = DEV_ASSET;
    switch (_env) {
        case 'dev':
            _server = DEV_ASSET;
            break;
        case 'production':
            _server = PROD_ASSET;
            break;
        case 'staging':
            _server = STAGING_ASSET;
            break;
        default:
            break;
    }
    return _server;
}

function getAgloExplorerURL() {
    let _server = DEV_ALGO_EXPLORER_URL;
    switch (_env) {
        case 'dev':
            _server = DEV_ALGO_EXPLORER_URL;
            break;
        case 'production':
            _server = PROD_ALGO_EXPLORER_URL;
            break;
        case 'staging':
            _server = STAGING_ALGO_EXPLORER_URL;
            break;
        default:
            break;
    }
    return _server;
}

function getAlgoClientApi() {
    let _server = DEV_ALGOCLIENT_API;
    switch (_env) {
        case 'dev':
            _server = DEV_ALGOCLIENT_API;
            break;
        case 'production':
            _server = PROD_ALGOCLIENT_API;
            break;
        case 'staging':
            _server = STAGING_ALGOCLIENT_API;
            break;
        default:
            break;
    }
    return _server;
}
