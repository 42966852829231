/* eslint-disable react-hooks/exhaustive-deps */
//css
import './style.scss';

import React from 'react';
//react
import { lazy, Suspense, useState, useEffect } from 'react';

//components
import Loader from 'old_components/Loader/Loader';
import ScrollToTop from 'old_components/ScrollToTop/ScrollToTop';

//material ui theme
import { ThemeProvider, createTheme } from '@mui/material/styles';

// import CssBaseline from '@mui/material/CssBaseline';
import { Snackbar, Alert } from '@mui/material';

//import context
import { UserContext } from 'old_components/Context/UserContext';
import { OverlayContext } from 'old_components/Context/OverlayContext';

//hooks
import useUserState from 'functions/hooks/useUserState';
import KycNotification from 'components/notification/KycNotification';
import KycWebSocket from 'components/WebSocket/KycWebSocket';
import { getLocal, isLoggedIn } from 'functions/helpers/auth';

//routes
const RenderRoutes = lazy(() => import('old_components/Routes/Routes'));

const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            paper: '#050711',
            default: '#050711',
        },
        text: {
            primary: '#f0f0f0',
        },
        primary: {
            main: '#5142FC',
        },
        secondary: {
            main: '#F259FF',
        },
    },
    typography: {
        fontSize: 20,
        fontFamily: ['Urbanist', 'sans-serif'].join(','),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1400,
        },
    },
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: '10px',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                text: {
                    fontSize: 15,
                    fontWeight: 700,
                },
                root: {
                    '&.Mui-completed': {
                        color: '#66A858',
                    },
                },
            },
        },
        MuiStepContent: {
            styleOverrides: {
                root: {
                    border: 'none',
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                line: {
                    border: 'none',
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    zIndex: 99990,
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    zIndex: 99991,
                    '& button:hover': {
                        color: 'inherit',
                        border: 'inherit',
                    },
                },
            },
        },
    },
});

function Root() {
    const [snackbar, setSnackbar] = useState({
        type: 'error',
        message: '',
        open: false,
    });

    const [loading, setLoading] = useState(false);

    const [webSocketStart, setWebSocketStart] = useState(false);
    const [socketKycStatus, setSocketKycStatus] = useState(false);
    const [disableStep, setDisableStep] = useState(false);
    const [notification, setNotification] = useState({
        showNotification: false,
        NotificationTitle: '',
        NotificationContent: '',
        NotificationOnClick: false,
        NotificationButtonTitle: '',
        showButton: true,
    });
    const loggedInStatus = isLoggedIn();
    useEffect(() => {
        if (loggedInStatus) {
            const user = getLocal('slug');
            const kycStatus = user.kyc_status;
            !kycStatus && setWebSocketStart(true);
            kycStatus && setSocketKycStatus('verified');
        }
    }, [loggedInStatus]);

    const {
        connectWallet,
        walletAddress,
        setWalletAddress,
        userType,
        setUserType,
        loadingBalance,
        walletData,
        setWalletData,
        checkBalance,
        disconnectWallet,
    } = useUserState({
        setSnackbar,
        setLoading,
        setNotification,
        setDisableStep,
    });

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbar({
            ...snackbar,
            open: false,
        });
    };

    return (
        <ThemeProvider theme={theme}>
            {/* <CssBaseline /> */}
            <OverlayContext.Provider
                value={{
                    setSnackbar,
                    loading,
                    setLoading,
                    setNotification,
                    disableStep,
                    setDisableStep,
                }}
            >
                <UserContext.Provider
                    value={{
                        walletAddress,
                        setWalletAddress,
                        userType,
                        setUserType,
                        loadingBalance,
                        walletData,
                        setWalletData,
                        connectWallet,
                        disconnectWallet,
                        checkBalance,
                        socketKycStatus,
                        setSocketKycStatus,
                    }}
                >
                    <Suspense fallback={<Loader open={true} />}>
                        <ScrollToTop />
                        <RenderRoutes />
                    </Suspense>
                    <Loader open={loading} />
                    <KycNotification {...notification} />
                    {webSocketStart && <KycWebSocket />}
                    <Snackbar
                        open={snackbar?.open}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert
                            onClose={handleCloseSnackbar}
                            severity={snackbar?.type}
                            sx={{ width: '100%' }}
                        >
                            {snackbar?.message}
                        </Alert>
                    </Snackbar>
                </UserContext.Provider>
            </OverlayContext.Provider>
        </ThemeProvider>
    );
}

export default Root;