import { isEmpty } from './utils';

export function getLocal(key) {
    try {
        return JSON.parse(localStorage.getItem(key));
    } catch (error) {
        return undefined;
    }
}

export function saveLocal(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
}

export function getToken() {
    return getLocal('slug');
}

export function isLoggedIn() {
    if (isEmpty(getToken())) return false;
    return !!getToken();
}

export function logout(reload = true) {
    // try {
    //   userAuth.logout();
    // } catch (error) {
    //   // pass
    // }
    localStorage.clear();
    localStorage.setItem('DisclaimerState', false);
    if (window && reload) {
        window.location.reload();
        window.location.replace('/');
    }
}

export function getRenewToken() {
    const profile = getLocal('profile');
    return profile?.renewToken || null;
}

export function saveToken(token) {
    // slug is actual token but saving it as slug just to confuse inspectors
    // TODO: encrypt token for noobs
    saveLocal('slug', token);
}

export function saveAuthToken(token) {
    saveLocal('Authorization', token);
}

export function getSession(key) {
    try {
        return JSON.parse(sessionStorage.getItem(key));
    } catch (error) {
        return undefined;
    }
}

export function saveSession(key, data) {
    sessionStorage.setItem(key, JSON.stringify(data));
}
export function removeFromSession(key) {
    sessionStorage.removeItem(key);
}

export function getAccountData(prop) {
    const account_data = getLocal('slug');
    return account_data?.[`${prop}`];
}
