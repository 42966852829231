import { getAccountData, saveToken } from './auth';
import profilev2Apis from 'functions/apis/profilev2';

export const updateUserSlug = () => {
    const userID = getAccountData('id');
    const userType = getAccountData('type');

    if (userType === 'creator') {
        profilev2Apis.getArtistProfile({ id: userID }, profileResponseHandler);
    } else if (userType === 'investor') {
        profilev2Apis.getInvestorProfile(
            { id: userID },
            profileResponseHandler
        );
    }
};
const profileResponseHandler = (err, res) => {
    if (err) {
        console.log(`err`, err);
    }
    if (res) {
        const user_data = res?.data[0];
        saveToken({
            ...user_data,
            type: user_data.user_type,
        });
    }
};
