

//css
import './Loader.scss'

import { Backdrop, CircularProgress } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    loading_backdrop: {
        color: '#fff', zIndex: 99999,
        '& .MuiCircularProgress-colorPrimary': {
            color: '#5142FC'
        }
    }
}))

const Loader = ({ open = false }) => {
    const classes = useStyles()

    return (
        <Backdrop
            className={classes.loading_backdrop}
            open={open}
        >
            <CircularProgress />
        </Backdrop>
    )
}

export default Loader