import api from './api';

let userAuthv2 = {};
userAuthv2.DNS = api.DNS.v2;

let endPoints = {
    checkAddressExists: '/user/check/address',
    checkUsernameExists: '/user/check/username',
    createUser: '/user/create',
    verifyCreator: '/user/getIsVerified',
};

const postApiMaker = (endPoint) => (data, cb) => {
    api.post(userAuthv2.DNS + endPoint, data, cb);
};

const putApiMaker = (endPoint) => (data, cb) => {
    api.put(userAuthv2.DNS + endPoint, data, cb);
};

const getApiMaker = (endPoint) => (data, cb) => {
    api.get(userAuthv2.DNS + endPoint, data, cb);
};

userAuthv2.checkAddressExists = getApiMaker(endPoints.checkAddressExists);

userAuthv2.checkUsernameExists = getApiMaker(endPoints.checkUsernameExists);
userAuthv2.verifyCreator = getApiMaker(endPoints.verifyCreator);
// userAuthv2.getImageUploadToken = getApiMaker(endPoints.getImageUploadToken)

userAuthv2.createUser = postApiMaker(endPoints.createUser);

export default userAuthv2;
