//css
import './App.css';

//router
import { BrowserRouter as Router } from 'react-router-dom';

//root
import Root from 'Root';

function App() {
    return (
        <Router>
            <Root />
        </Router>
    );
}

export default App;
